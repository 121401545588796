<template>
  <v-dialog
    v-model="shower"
    scrollable
    persistent
    max-width="500px"
    v-if="resolutionScreen >= 500"
  >
    <v-card>
      <v-card-text class="pa-1 pt-4 pb-1 text-center" style="font-size: 18px; height:100px">
        <p class="pl-4 ma-2">
          {{ $t("dialogconfirmdelete.noti_emptytrash") }}
        </p>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" outlined @click="$emit('close')">{{
          $t("changeName.close")
        }}</v-btn>
        <v-btn :color="color.theme" dark @click="$emit('confirmemptytrash')">{{
          $t("changeName.submit")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- หน้าจอมือถือ -->
  <v-dialog
    v-model="shower"
    scrollable
    persistent
    :max-width="maxWidthOnMobile"
    content-class="rounded-lg"
    v-else
  >
    <v-card>
      <v-card-text class="px-4 pt-1 pb-1 text-center" :style="titleText">
        <p>
          {{ $t("dialogconfirmdelete.noti_emptytrash") }}
        </p>
      </v-card-text>
      <div class="text-center my-4">
        <v-btn
          :style="btnAction"
          class="mr-6"
          height="40px"
          width="40%"
          color="red"
          outlined
          @click="$emit('closedialog')"
          >{{ $t("changeName.close") }}</v-btn
        >
        <v-btn
          height="40px"
          width="40%"
          class="elevation-0"
          :style="btnAction"
          :color="color.theme"
          dark
          @click="$emit('reload')"
          >{{ $t("changeName.submit") }}</v-btn
        >
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
export default {
  props: ["show", "confirmdel", "filedata"],
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        if (this.show === true) {
        }
        return this.show;
      },
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    // style อักษร header ของ mobile
    headerPage() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
      );
    },
    // style ปุ่ม ของ mobile
    btnAction() {
      return "font-size: 16px; font-weight: lighter;";
    },
    // style อักษรใน dialog ของ mobile
    titleText() {
      return "color:black; font-size: 18px; line-height: 28px;";
    },
    // ความกว้างของ dialog ของ ipad และ mobile
    maxWidthOnMobile() {
      if(this.resolutionScreen >= 400) {
        return 600
      } else {
        return 346
      }
    },
  },
};
</script>